import { CoursePricingNavData } from "@/pages/course/interfaces";
import { useRouter } from "next/router";
import DiscountBadge from "../Badge/Discount"
import { submitLeadForm } from "@/helpers/api/service";
import { useSelector } from "react-redux";
import { selectStudentId, selectUser } from "@/store/slices/auth";

const CoursePricingNav = (props: { data: CoursePricingNavData }) => {
  const { amount_strike_through, amount_to_pay, discount, emi, monthly_price, variant_id } = props.data;
  const router = useRouter();
  const user = useSelector(selectUser);
  const studentId = useSelector(selectStudentId);

  const checkout = () => {
    router.push(`/checkout?variant_id=${variant_id}&payment_for=course_package&url='/my-course'`);
    if (!user.id) return;
    const formResponseObj = {
      source: router.asPath,
      studentId,
      verifiedStudentId: user.id,
    }
    submitLeadForm(formResponseObj);
  }

  return (
    <div className="bg-secondary-light shadow-2xl fixed bottom-0 left-0 right-0 lg:hidden z-50 drop-shadow-lg rounded-t-lg">
      <div className="flex flex-col gap-4 p-4">
        <div>
          <div className="flex justify-between items-center">
            <div className="flex gap-3 items-center">
              <span className="text-2xl font-bold">{amount_to_pay}</span>
              {amount_strike_through && <span className="line-through text-gray-500">{amount_strike_through}</span>}
            </div>
            <DiscountBadge className="bg-green-700 text-white rounded p-2" text={discount} />
          </div>
          {emi && monthly_price && <div className="mt-2">{`${emi} @ ${monthly_price}`}</div>}
        </div>
        <div>
          <button className="w-full btn-primary" onClick={checkout}>Get Admission</button>
        </div>
      </div>
    </div>
  )
}

export default CoursePricingNav