import BottomNav from "@/components/BottomNavbar";
import dynamic from "next/dynamic";
import { memo, useMemo } from "react";
import { DEEPLINK_PREFIX, STATIC_CDN } from "@/config/constants";
import Header from "@/components/Header";
import ClosableBanner from "@/components/Banner/ClosableBanner";
import { inter } from "@/helpers/font";

const Footer = dynamic(() => import("@/components/Footer"));
const VideoModal = dynamic(() => import("@/components/Dialog/Video"));

const CommonLayout = (props: { children: React.ReactNode }) => {
  const pageProps = (props.children as any)?.[0]?.props?.children?.props;
  const pathName = pageProps?.pathName;
  const breadcrumbs = pageProps?.breadcrumbs;
  const { class: studentClass, target_group: targetGroup, target_group_type: targetGroupType } = pageProps?.videoData || {};

  
  const isEnglishMedium = pageProps?.videoData?.video_locale === "en" || pageProps?.videoData?.video_locale === "hi-en";
  const isTargetClass = (studentClass >= 6 && studentClass <= 10) && isEnglishMedium;

  const imageBannerData = useMemo( () =>
    ({
      imgUrl: isTargetClass ? `${STATIC_CDN}/engagement_framework/3F16D16C-FB73-BB71-23A0-416E7DA4812A.webp` : (studentClass && targetGroup && targetGroupType) ?  `${STATIC_CDN}/web_banners/top-sticky-promotions-banner/${studentClass}-${targetGroup.toUpperCase().replace(/\s+/g, "-")}-${targetGroupType.toUpperCase().replace(/\s+/g, "-")}.webp` : `${STATIC_CDN}/web_banners/top-sticky-promotions-banner/default.webp`,
      alt: "Doubtnut Promotions Sticky",
      href: isTargetClass ? `https://studyonline.tallentex.com/tex2025/allen-educational-talent-search-program?utm_source=doubtnut&utm_medium=referrral&utm_campaign=dn_scholarship_tx25` : `${DEEPLINK_PREFIX}/_s6cQrh9d5NLz`,
      width: 360,
      height: 90,
      closable: false,
    }), [studentClass, targetGroup, targetGroupType]);


  return (
    <div id="base-layout" className={`${inter.variable} font-sans`}>
      <ClosableBanner data={imageBannerData} />
      <Header breadcrumbs={breadcrumbs} />
      {props.children}
      <Footer />
      <VideoModal page={pathName} />
      <BottomNav pageProps={pageProps}/>
    </div>
  );
};

export default memo(CommonLayout);